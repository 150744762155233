/* Custom using surveyswap color and font */
:root {
  --sjs-general-backcolor: rgba(255, 255, 255, 1);
  --sjs-general-backcolor-dark: rgba(248, 248, 248, 1);
  --sjs-general-backcolor-dim: rgba(243, 243, 243, 1);
  --sjs-general-backcolor-dim-light: rgba(249, 249, 249, 1);
  --sjs-general-backcolor-dim-dark: rgba(243, 243, 243, 1);
  --sjs-general-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-forecolor-light: rgba(0, 0, 0, 0.45);
  --sjs-general-dim-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-dim-forecolor-light: rgba(0, 0, 0, 0.45);
  --sjs-primary-backcolor: rgba(63, 74, 240, 1);
  --sjs-primary-backcolor-light: rgba(63, 74, 240, 0);
  --sjs-primary-backcolor-dark: rgba(54, 63, 204, 1);
  --sjs-primary-forecolor: rgba(255, 255, 255, 1);
  --sjs-primary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-base-unit: 8px;
  --sjs-corner-radius: 4px;
  --sjs-secondary-backcolor: rgba(252, 211, 77, 1);
  --sjs-secondary-backcolor-light: rgba(252, 211, 77, 0.1);
  --sjs-secondary-backcolor-semi-light: rgba(252, 211, 77, 0.25);
  --sjs-secondary-forecolor: rgba(255, 255, 255, 1);
  --sjs-secondary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-shadow-small: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  --sjs-shadow-medium: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  --sjs-shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  --sjs-shadow-inner: inset 0px 1px 2px 0px rgba(221, 0, 0, 0.15);
  --sjs-border-light: rgba(0, 0, 0, 0.09);
  --sjs-border-default: rgba(0, 0, 0, 0.16);
  --sjs-border-inside: rgba(0, 0, 0, 0.16);
  --sjs-special-red: rgba(229, 10, 62, 1);
  --sjs-special-red-light: rgba(229, 10, 62, 0.1);
  --sjs-special-red-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-green: rgba(25, 179, 148, 1);
  --sjs-special-green-light: rgba(25, 179, 148, 0.1);
  --sjs-special-green-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-blue: rgba(67, 127, 217, 1);
  --sjs-special-blue-light: rgba(67, 127, 217, 0.1);
  --sjs-special-blue-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-yellow: rgba(255, 152, 20, 1);
  --sjs-special-yellow-light: rgba(255, 152, 20, 0.1);
  --sjs-special-yellow-forecolor: rgba(255, 255, 255, 1);
  --sjs-article-font-xx-large-fontSize: 64px;
  --sjs-article-font-xx-large-textDecoration: none;
  --sjs-article-font-xx-large-fontWeight: 700;
  --sjs-article-font-xx-large-fontStyle: normal;
  --sjs-article-font-xx-large-fontStretch: normal;
  --sjs-article-font-xx-large-letterSpacing: 0;
  --sjs-article-font-xx-large-lineHeight: 64px;
  --sjs-article-font-xx-large-paragraphIndent: 0px;
  --sjs-article-font-xx-large-textCase: none;
  --sjs-article-font-x-large-fontSize: 48px;
  --sjs-article-font-x-large-textDecoration: none;
  --sjs-article-font-x-large-fontWeight: 700;
  --sjs-article-font-x-large-fontStyle: normal;
  --sjs-article-font-x-large-fontStretch: normal;
  --sjs-article-font-x-large-letterSpacing: 0;
  --sjs-article-font-x-large-lineHeight: 56px;
  --sjs-article-font-x-large-paragraphIndent: 0px;
  --sjs-article-font-x-large-textCase: none;
  --sjs-article-font-large-fontSize: 32px;
  --sjs-article-font-large-textDecoration: none;
  --sjs-article-font-large-fontWeight: 700;
  --sjs-article-font-large-fontStyle: normal;
  --sjs-article-font-large-fontStretch: normal;
  --sjs-article-font-large-letterSpacing: 0;
  --sjs-article-font-large-lineHeight: 40px;
  --sjs-article-font-large-paragraphIndent: 0px;
  --sjs-article-font-large-textCase: none;
  --sjs-article-font-medium-fontSize: 24px;
  --sjs-article-font-medium-textDecoration: none;
  --sjs-article-font-medium-fontWeight: 700;
  --sjs-article-font-medium-fontStyle: normal;
  --sjs-article-font-medium-fontStretch: normal;
  --sjs-article-font-medium-letterSpacing: 0;
  --sjs-article-font-medium-lineHeight: 32px;
  --sjs-article-font-medium-paragraphIndent: 0px;
  --sjs-article-font-medium-textCase: none;
  --sjs-article-font-default-fontSize: 16px;
  --sjs-article-font-default-textDecoration: none;
  --sjs-article-font-default-fontWeight: 400;
  --sjs-article-font-default-fontStyle: normal;
  --sjs-article-font-default-fontStretch: normal;
  --sjs-article-font-default-letterSpacing: 0;
  --sjs-article-font-default-lineHeight: 28px;
  --sjs-article-font-default-paragraphIndent: 0px;
  --sjs-article-font-default-textCase: none;
  --font-family: Inter, sans-serif;
  --sjs-font-questiontitle-family: Inter, sans-serif;
  /* --sjs-font-questiontitle-color: rgba(63, 74, 240, 0.91); */
  --sjs-font-questiontitle-color: rgba(0, 0, 0, 0.8);
  /* --sjs-font-questiondescription-color: rgba(63, 74, 240, 0.45); */
  --sjs-font-questiondescription-color: rgba(0, 0, 0, 0.91);
  /* --sjs-font-editorfont-color: rgba(63, 74, 240, 0.91); */
  --sjs-font-editorfont-color: rgba(0, 0, 0, 0.91);
  /* --sjs-font-editorfont-placeholdercolor: rgba(63, 74, 240, 0.5); */
  --sjs-font-editorfont-placeholdercolor: rgba(0, 0, 0, 0.91);
  /* --sjs-font-pagetitle-color: rgba(63, 74, 240, 0.91); */
  --sjs-font-pagetitle-color: rgba(0, 0, 0, 0.8);
}

.sd-question__description {
  color: var(--sjs-font-questiondescription-color); /* Uses your theme color */
  opacity: 0.6; /* Makes it slightly lighter */
}

.sd-footer.sd-body__navigation {
  display: flex;
  justify-content: center;
  gap: 16px; /* Space between Previous and Next buttons */
}

/* Option 2: If option 1 doesn't work, target the container and buttons specifically */
.sd-footer.sd-body__navigation {
  text-align: center;
}

.sd-btn.sd-navigation__prev-btn,
.sd-btn.sd-navigation__next-btn {
  float: none;
  display: inline-block;
  margin: 0 8px; /* Adds space between buttons */
}
