$sidebar-bg-color: white !default;
$sidebar-color: black !default;
$sidebar-width: 220px !default;
$sidebar-collapsed-width: 77px !default;
$highlight-color: black !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;
