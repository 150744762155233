.radioinput-cmp {
  @apply block relative cursor-pointer;
  padding-left: 28px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radioinput */
.radioinput-cmp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiomark {
  @apply absolute top-0 left-0 bg-white border border-blue-500 w-4 h-4 rounded-full flex items-center justify-center;
}

.radiomark-middle {
  @apply bg-white w-3 h-3 rounded-full;
}

/* On mouse-over, add a grey background color */
.radioinput-cmp:hover input ~ .radiomark {
  @apply bg-gray-400;
}

/* When the radioinput is radioed, add a blue background */
.radioinput-cmp input:checked ~ .radiomark > .radiomark-middle {
  @apply bg-blue-500;
}
