@import './styles/fonts/caveat.css';
@import './styles/fonts/inter.css';
@import './styles/surveyjs.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.ReactModal__Body--open {
  @apply overflow-hidden;
}

@layer base {
  html,
  body {
    @apply font-sans m-0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    @apply text-4xl leading-tight;
  }
}

html {
  scroll-behavior: smooth;
}

body span .__fbcw__widget {
  display: none !important;
}

body #feedback .__fbcw__widget {
  display: inline-block !important;
}

body #feedback .__fbcw__widget .__fbcw__widget__stars {
  margin-bottom: 0 !important;
}

body
  #feedback
  .__fbcw__widget
  .__fbcw__stars.__fbcw__stars--responsive
  .__fbcw__stars__star::after,
body
  #feedback
  .__fbcw__widget
  .__fbcw__stars.__fbcw__stars--responsive
  .__fbcw__stars__star::before {
  width: 60% !important;
  height: 60% !important;
}

body #feedback .__fbcw__widget .__fbcw__widget__rating-overview {
  max-width: 212px !important;
  margin: 20px auto 0 !important;
}

body #feedback .__fbcw__widget .__fbcw__widget__stars,
body #feedback .__fbcw__widget .__fbcw__widget__stats-rating {
  justify-content: start !important;
  align-items: center !important;
}

body #feedback .__fbcw__widget .__fbcw__widget__review-holder {
  margin-top: 10px !important;
  border-top: 1px solid #f1f1f1 !important;
}

body #feedback .__fbcw__widget__stats-divider {
  background-color: transparent !important;
}

body #feedback .__fbcw__widget__divider-holder {
  display: none !important;
}

body #feedback .__fbcw__review__rating-holder span {
  color: black !important;
}

body #feedback .__fbcw__widget__stats-rating .__fbcw__widget_label:last-child {
  opacity: 0.7 !important;
}

/* width */
::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-700;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-blue-700 rounded-md;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-blue-600;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.tr-separator {
  height: 10px;
}

/* Helpdesk widget */
.BeaconFabButtonFrame {
  bottom: 90px !important;
}

.BeaconContainer {
  bottom: 155px !important;
  height: calc(100% - 175px) !important;
}

@media screen and (max-height: 740px) {
  .dashboard-hello-title {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-size: 28px !important;
  }
}

/* Hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}

/* Segment type select input on onboarding */
@media screen and (max-width: 767px) {
  #segment_type .select__value-container,
  #segment_type .select__menu .select__option {
    font-size: 12px;
  }
}

/* Other */

.z-100 {
  z-index: 100;
}

.no-cursor-pointer {
  cursor: default !important;
}
/* Spin animation */
.animate-spin-slow {
  animation: spin-slow 20s linear infinite;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Counter rotation */
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo {
  animation: counter-spin-slow 20s linear infinite;
}

@keyframes counter-spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
