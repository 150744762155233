@keyframes go2290013861 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes go2939509526 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
#tm-w486xf .go2402097865,
#tm-w486xf .go2402097865 *,
#tm-w486xf .go2402097865 *::before,
#tm-w486xf .go2402097865 *::after {
  animation: revert;
  background: revert;
  border: revert;
  position: revert;
  display: revert;
  columns: revert;
  margin: revert;
  padding: revert;
  list-style: revert;
  border-radius: revert;
  box-shadow: revert;
  color: revert;
  font-size: revert;
  font-style: revert;
  font-weight: revert;
  text-align: revert;
  text-decoration: revert;
  text-indent: revert;
  text-shadow: revert;
  text-transform: revert;
  transform: revert;
  transition: revert;
  vertical-align: revert;
  word-spacing: revert;
  word-wrap: revert;
  word-break: revert;
  quotes: revert;
  float: revert;
  clear: revert;
  grid: revert;
  offset: revert;
  opacity: revert;
  outline: revert;
  overflow: revert;
  height: revert;
  width: revert;
  min-height: revert;
  min-width: revert;
  max-height: revert;
  max-width: revert;
  top: revert;
  right: revert;
  bottom: revert;
  left: revert;
  line-height: revert;
  resize: revert;
  z-index: revert;
  -webkit-box-decoration-break: revert;
  -webkit-font-smoothing: revert;
  -webkit-hyphenate-character: revert;
  box-sizing: border-box;
}
#tm-w486xf .go2402097865 img {
  max-width: 100%;
}
#tm-w486xf div.go941208248 {
  font-size: 16px;
  font-family: Roboto;
}
#tm-w486xf div.go316439965 {
  font-size: 16px;
  padding: 2.5em 0;
}
#tm-w486xf h2.go372176402 {
  text-align: center;
  margin-bottom: 1em;
  line-height: 1.2;
  color: #000000;
}
@media screen and (max-width: 400px) {
  #tm-w486xf h2.go372176402 {
    font-size: 1.3em;
  }
}
#tm-w486xf div.go2990454101 {
  max-width: 50em;
  margin: 0 auto 1em auto;
  padding: 0 20px;
  color: #7b8697;
  text-align: center;
}
@media screen and (max-width: 400px) {
  #tm-w486xf div.go2990454101 {
    font-size: 0.9em;
  }
}
#tm-w486xf div.go3338470317 {
  padding: 0 calc(48px + 0.75em);
  margin-bottom: 2em;
}
@media screen and (max-width: 644px) {
  #tm-w486xf div.go3338470317 {
    padding: 0 calc(24px + 0.75em);
  }
}
@media screen and (max-width: 420px) {
  #tm-w486xf div.go3338470317 {
    padding: 0 0.75em;
  }
}
/* #tm-w486xf div.go1305758281 {
  border-radius: 1em;
  box-shadow: 0 0.75em 1.5em -0.25em #919eab1f, 0 0 0.125em 0 #919eab33;
  background: #ffffff;
  overflow: hidden;
} */

.tm-white-text,
.tm-white-text b,
.tm-white-text .go1038471815 b {
  color: white !important;
}

#tm-w486xf div.go3325113030 {
  display: flex;
  flex-wrap: wrap;
  color: #000;
  padding: 1.5em;
  width: 100%;
  gap: 1em;
  justify-content: center;
}
#tm-w486xf div.go1038471815 {
  display: flex;
  flex-wrap: wrap;
  /* gap: 1em; */
  gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
}
#tm-w486xf .go1038471815 .tm-source-logo {
  height: 1em;
  width: 1em;
}
#tm-w486xf .go1038471815 span {
  font-size: 0.875em;
  color: #636e81;
}
#tm-w486xf .go1038471815 b {
  color: #000;
}
#tm-w486xf div.go3304816377 {
  display: flex;
}
#tm-w486xf img.go1914879238 {
  display: block;
  width: 1em;
  height: 1em;
}
#tm-w486xf div.go271816224 {
  display: flex;
  height: 1.125em;
  width: 5.625em;
  position: relative;
}
#tm-w486xf .go271816224:before,
#tm-w486xf .go271816224 > div {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.9 20.89C6.56214 21.0677 6.1526 21.0375 5.84443 20.8122C5.53626 20.5869 5.38323 20.2058 5.45 19.83L6.45 14.2L2.33 10.2C2.06806 9.9386 1.97189 9.55391 2.08 9.2C2.19825 8.83742 2.51242 8.57366 2.89 8.52L8.59 7.69L11.1 2.56C11.2671 2.21501 11.6167 1.99589 12 1.99589C12.3833 1.99589 12.7329 2.21501 12.9 2.56L15.44 7.68L21.14 8.51C21.5176 8.56366 21.8318 8.82742 21.95 9.19C22.0581 9.54391 21.9619 9.9286 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z' fill='%23e6e8e8' /%3E%3C/svg%3E%0A");
  background-size: 1.125em;
  position: absolute;
  top: 0;
  left: 0;
}
#tm-w486xf .go271816224 > div {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.9 20.89C6.56214 21.0677 6.1526 21.0375 5.84443 20.8122C5.53626 20.5869 5.38323 20.2058 5.45 19.83L6.45 14.2L2.33 10.2C2.06806 9.9386 1.97189 9.55391 2.08 9.2C2.19825 8.83742 2.51242 8.57366 2.89 8.52L8.59 7.69L11.1 2.56C11.2671 2.21501 11.6167 1.99589 12 1.99589C12.3833 1.99589 12.7329 2.21501 12.9 2.56L15.44 7.68L21.14 8.51C21.5176 8.56366 21.8318 8.82742 21.95 9.19C22.0581 9.54391 21.9619 9.9286 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z' fill='%23ffca05' /%3E%3C/svg%3E%0A");
}
#tm-w486xf div.go3491326440 {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}
@media screen and (max-width: 38em) {
  #tm-w486xf div.go3491326440 {
    justify-content: center;
  }
}
#tm-w486xf .go3864472833 .tm-carousel-item {
  padding: 0.75em;
  transition: 0.4s opacity ease-in-out;
}
#tm-w486xf .go3864472833 .tm-carousel-outside {
  opacity: 0;
}
@media screen and (max-width: 645px) {
  #tm-w486xf .go3864472833 .tm-carousel-outside {
    opacity: 0.3;
  }
}
@media screen and (max-width: 420px) {
  #tm-w486xf .go3864472833 .tm-carousel-outside {
    opacity: 1;
  }
}
#tm-w486xf .go3864472833 .tm-card-wrapper {
  height: 100%;
}
#tm-w486xf div.go4269172405 {
  padding: 2.5em 1em 0;
  text-align: center;
}
#tm-w486xf .go4269172405 .tm-powered-by,
#tm-w486xf .go4269172405 .tm-powered-by svg {
  height: 1.5em;
}
#tm-w486xf .go4269172405 .tm-powered-by-text svg {
  height: 1em;
}
#tm-w486xf div.go2048136271 {
  background: #fff;
  border-radius: 0.5em;
  display: inline-block;
  padding: 0.5em 0.75em;
}
#tm-w486xf span.go1476009939 {
  display: flex !important;
  visibility: visible !important;
  position: static !important;
  opacity: 1 !important;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
#tm-w486xf span.go3873761283 {
  color: #636e81;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}
#tm-w486xf a.go3297426415 {
  color: #636e81;
  text-decoration: none;
}
#tm-w486xf .go3297426415:hover {
  text-decoration: underline;
}
#tm-w486xf span.go1392771034 {
  display: inline-block;
  text-transform: lowercase;
  font-weight: 700;
  background-image: url('https://d2nce6johdc51d.cloudfront.net/source/trustmary.svg');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 10px 18px;
  padding-left: 13px;
  color: #0d3b70;
  margin-left: 2px;
}
#tm-w486xf div.go3136508558 {
  overflow: hidden;
  position: relative;
  direction: ltr;
}
#tm-w486xf div.go2617933356 {
  display: flex;
  width: 100%;
  margin: auto 0;
}
#tm-w486xf div.go3046547012 {
  background: #ffffff;
  border-radius: 1em;
  padding: 1.5em;
  box-shadow: 0 0.75em 1.5em -0.25em #919eab1f, 0 0 0.125em 0 #919eab33;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 1em;
  line-height: 1.5em;
  flex: 1;
}
#tm-w486xf div.go3116110566 {
  display: flex;
  align-items: center;
  gap: 1em;
  text-align: start;
}
#tm-w486xf .go3116110566 .tm-bottom-picture {
  display: flex;
  align-items: center;
}
#tm-w486xf .go3116110566 .tm-bottom-picture img {
  display: block;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  object-fit: cover;
}
#tm-w486xf .go3116110566 .tm-bottom-person {
  flex: 1;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
}
#tm-w486xf .go3116110566 .tm-bottom-person b {
  display: block;
  font-weight: 600;
  color: #1d3a6c;
}
#tm-w486xf .go3116110566 .tm-bottom-person span {
  display: block;
  color: #5f7482;
}
#tm-w486xf div.go3966434175 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}
#tm-w486xf .go3966434175 .tm-spacer {
  flex: 1;
}
#tm-w486xf .go3966434175 .tm-time {
  color: #5f7482;
}
#tm-w486xf div.go2042897421 {
  display: flex;
  height: 1em;
  width: 5em;
  position: relative;
}
#tm-w486xf .go2042897421:before,
#tm-w486xf .go2042897421 > div {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.9 20.89C6.56214 21.0677 6.1526 21.0375 5.84443 20.8122C5.53626 20.5869 5.38323 20.2058 5.45 19.83L6.45 14.2L2.33 10.2C2.06806 9.9386 1.97189 9.55391 2.08 9.2C2.19825 8.83742 2.51242 8.57366 2.89 8.52L8.59 7.69L11.1 2.56C11.2671 2.21501 11.6167 1.99589 12 1.99589C12.3833 1.99589 12.7329 2.21501 12.9 2.56L15.44 7.68L21.14 8.51C21.5176 8.56366 21.8318 8.82742 21.95 9.19C22.0581 9.54391 21.9619 9.9286 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z' fill='%23e6e8e8' /%3E%3C/svg%3E%0A");
  background-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}
#tm-w486xf .go2042897421 > div {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.9 20.89C6.56214 21.0677 6.1526 21.0375 5.84443 20.8122C5.53626 20.5869 5.38323 20.2058 5.45 19.83L6.45 14.2L2.33 10.2C2.06806 9.9386 1.97189 9.55391 2.08 9.2C2.19825 8.83742 2.51242 8.57366 2.89 8.52L8.59 7.69L11.1 2.56C11.2671 2.21501 11.6167 1.99589 12 1.99589C12.3833 1.99589 12.7329 2.21501 12.9 2.56L15.44 7.68L21.14 8.51C21.5176 8.56366 21.8318 8.82742 21.95 9.19C22.0581 9.54391 21.9619 9.9286 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z' fill='%23ffca05' /%3E%3C/svg%3E%0A");
}
#tm-w486xf div.go3073557368 {
  flex: 1;
}
#tm-w486xf .go108640458 > div {
  text-align: start;
}
#tm-w486xf div.go4008414990 {
  color: #000;
}
#tm-w486xf div.go3658123450 {
  display: flex;
  align-items: center;
  gap: 0.4em;
}
#tm-w486xf .go3658123450 span {
  font-size: 0.75em;
  color: #5f7482;
}
#tm-w486xf .go3658123450 .tm-source-logo {
  display: flex;
  align-items: center;
}
#tm-w486xf button.go3081471604 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateY(-20px);
  background: none;
  border: none;
  padding: 1.5em 1em;
  z-index: 3;
  opacity: 0.6;
  cursor: pointer;
  left: 1em;
}
#tm-w486xf .go3081471604:hover {
  opacity: 1;
}
#tm-w486xf .go3081471604 svg {
  display: block;
}
#tm-w486xf button.go426803419 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateY(-20px);
  background: none;
  border: none;
  padding: 1.5em 1em;
  z-index: 3;
  opacity: 0.6;
  cursor: pointer;
  right: 1em;
}
#tm-w486xf .go426803419:hover {
  opacity: 1;
}
#tm-w486xf .go426803419 svg {
  display: block;
}
#tm-w486xf div.go3374801529 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
}
#tm-w486xf button.go3889205382 {
  background: none;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
}
#tm-w486xf .go3889205382:hover .tm-carousel-dot-circle {
  opacity: 1;
}
#tm-w486xf span.go3076394835 {
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d3a6c;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.2s all ease-in-out;
}
#tm-w486xf span.go2083181050 {
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d3a6c;
  opacity: 0.6;
  transform: scale(1);
  transition: transform 0.2s all ease-in-out;
}
#tm-w486xf span.go2893994171 {
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d3a6c;
  opacity: 0.6;
  transform: scale(0.8);
  transition: transform 0.2s all ease-in-out;
}
#tm-w486xf span.go525568881 {
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d3a6c;
  opacity: 0.6;
  transform: scale(0.6);
  transition: transform 0.2s all ease-in-out;
}
#tm-w486xf div.go358415333 {
  font-size: 13px;
  font-weight: 700;
  opacity: 0.6;
  margin-left: 4px;
  line-height: 20px;
}
