.checkbox-cmp {
  @apply block relative cursor-pointer;
  padding-left: 28px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-cmp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  @apply absolute top-0 left-0 bg-white border border-blue-500 w-4 h-4 rounded mt-1;
}

/* On mouse-over, add a grey background color */
.checkbox-cmp:hover input ~ .checkmark {
  @apply bg-gray-400;
}

/* When the checkbox is checked, add a blue background */
.checkbox-cmp input:checked ~ .checkmark {
  @apply bg-blue-500;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-cmp input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-cmp .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
}
