body .select__control {
  @apply outline-none w-full border border-blue-600 rounded-md mt-3 mb-3 px-3 hover:border-blue-500 focus:ring-2 focus:ring-blue-500;
  min-height: 3rem;
}
body .react-select--invalid .select__control {
  @apply border-red-500;
}
body .select__control .select__value-container {
  @apply px-0 text-black text-base font-medium w-12 flex overflow-hidden whitespace-nowrap overflow-ellipsis;
}
body .select__control .select__value-container .select__placeholder {
  @apply text-gray-200 text-base mx-0 font-medium w-full;
}
body .select__control .select__indicator {
  @apply text-blue-500 hover:bg-blue-700 rounded-md focus:bg-blue-700;
}
body .select__control .select__indicator-separator {
  @apply hidden;
}
body .select__menu {
  @apply shadow-md border border-blue-700 hover:text-black p-2;
}
body .select__menu .select__menu-list .select__option {
  @apply hover:bg-gray-700 focus:bg-gray-700 font-medium active:bg-gray-700 rounded-md text-black text-base cursor-pointer;
}
body .select__menu .select__menu-list .select__option--is-selected {
  @apply bg-blue-700 text-black text-base font-medium cursor-pointer;
}
body .select__menu .select__menu-list .select__option--is-focused {
  @apply bg-gray-700 font-medium;
}
body .select__control .select__value-container .select__multi-value {
  @apply bg-blue-700 rounded text-blue-500;
}
body
  .select__control
  .select__value-container
  .select__multi-value
  .select__multi-value__label {
  @apply text-blue-500;
}
