.Toastify__toast-container {
  width: 100%;
  max-width: 480px;
}
.Toastify__close-button {
  @apply opacity-100 m-auto;
}

.Toastify__toast {
  @apply text-xs md:text-sm rounded-full flex items-center justify-center px-4 max-h-20 min-h-full;
}
.Toastify__toast-body {
  @apply m-0 p-0 flex items-center;
}
.Toastify__toast-body img {
  @apply mr-2  w-6 h-auto;
}
.Toastify__toast--default {
  background: #3f4af0;
  color: #fff;
}
.Toastify__toast--info {
  background: #3f4af0;
  color: #fff;
}
.Toastify__toast--success {
  background: #32c08d;
  color: #fff;
}

.Toastify__progress-bar--default {
  background: rgba(255, 255, 255, 0.5);
}
