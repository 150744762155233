.bg-blue-gradient {
  background: rgb(29, 21, 168);
  background: linear-gradient(
    0deg,
    rgba(29, 21, 168, 1) 0%,
    rgba(34, 55, 185, 1) 18%,
    rgba(35, 90, 217, 1) 100%
  );
}

.flex-grow-evenly {
  flex: 1 1 0;
}
