.z-highest {
  z-index: 102;
}

.ReactModal__Overlay {
  z-index: 102 !important;
}

.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}
