/* From the raw HTML of TrustPilot */
.tp-widget-rating {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
  font-weight: 300;
}
.tp-widget-rating .score,
.tp-widget-trustscore,
.tp-widget-empty-horizontal__title,
.tp-widget-empty-vertical__title,
.bold-underline {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 500;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tp-widget-rating .score,
  .tp-widget-trustscore,
  .tp-widget-empty-horizontal__title,
  .tp-widget-empty-vertical__title,
  .bold-underline {
    font-weight: 600;
  }
}

.tp-widget-loader,
.tp-widget-fallback {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
#fallback-logo {
  display: none;
}
.no-svg #fallback-logo {
  display: inline-block;
}
.tp-widget-wrapper {
  height: 100%;
  margin: 0 auto;
  max-width: 750px;
  position: relative;
}
@media screen and (min-width: 750px) {
  .tp-widget-wrapper--fullwidth {
    max-width: 100%;
  }
  .tp-widget-wrapper--fullwidth .tp-widget-attributes-scores-wrapper {
    border-bottom: 1px solid #d6d6d6;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  .tp-widget-wrapper--fullwidth
    .tp-widget-attributes-scores-wrapper
    .tp-widget-scores,
  .tp-widget-wrapper--fullwidth
    .tp-widget-attributes-scores-wrapper
    .tp-widget-attributes {
    border-bottom: none;
  }
  .tp-widget-wrapper--fullwidth
    .tp-widget-attributes-scores-wrapper
    .tp-widget-scores {
    -ms-grid-row: 1;
    grid-row: 1;
  }
}
.tp-stars .tp-star__canvas,
.tp-stars .tp-star__canvas--half {
  fill: #dcdce6;
}
.tp-stars .tp-star__shape {
  fill: #fff;
}
.tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas,
.tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas--half {
  fill: #ff3722;
}
.tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas,
.tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
  fill: #ff8622;
}
.tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas,
.tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
  fill: #ffce00;
}
.tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
.tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #73cf11;
}
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas,
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-stars--1--half .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
  fill: #ff3722;
}
.tp-stars--2--half .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
  fill: #ff8622;
}
.tp-stars--3--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #ffce00;
}
.tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
.tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-stars--4--half .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-widget-loader,
.tp-widget-fallback {
  max-width: 50%;
  text-align: center;
  width: 400vh;
}
.tp-widget-loader svg,
.tp-widget-fallback svg {
  width: 100%;
}
@media screen and (min-width: 400px) {
  .tp-widget-loader,
  .tp-widget-fallback {
    max-width: 200px;
  }
}
.tp-widget-loader--loaded {
  opacity: 0;
}
.bold-underline {
  border-bottom: 1px solid rgba(25, 25, 25, 0.6);
}
.bold-underline:hover {
  border-color: #191919;
}
.dark {
  color: #fff;
}
.dark a {
  color: #fff;
}
.dark .bold-underline:not(.bold-underline--single-theme) {
  border-color: rgba(255, 255, 255, 0.6);
}
.dark .bold-underline:not(.bold-underline--single-theme):hover {
  border-color: #fff;
}
.dark .tp-logo__text {
  fill: #fff;
}
.dark .tp-widget-loader .tp-logo__text {
  fill: #191919;
}
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.tp-widget-empty-vertical {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}
.tp-widget-empty-vertical__title {
  font-size: 18px;
  margin: 0 0 12px;
  text-align: center;
}
.tp-widget-empty-vertical__stars {
  margin: 0 0 8px;
  width: 150px;
}
.tp-widget-empty-vertical__subtitle-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.tp-widget-empty-vertical__subtitle {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  text-align: center;
}
.tp-widget-empty-vertical__logo {
  margin: 0 0 5px 5px;
  width: 90px;
}
.tp-widget-empty-horizontal {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
  -ms-flex-pack: center;
  justify-content: center;
}
.tp-widget-empty-horizontal__title {
  font-size: 14px;
  text-align: center;
}
.tp-widget-empty-horizontal__logo {
  margin: 0 0 5px 5px;
  width: 90px;
}
@media screen and (max-width: 300px) {
  .tp-widget-empty-vertical__subtitle-wrapper {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tp-widget-empty-vertical__logo {
    margin: 5px 0 0;
  }
  .tp-widget-empty-horizontal {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tp-widget-empty-horizontal__logo {
    margin: 0;
  }
}
.tp-widget-wrapper {
  text-align: center;
}
.tp-widget-trustscore {
  font-size: 14px;
  margin: 0 0 2px;
}
.tp-widget-rating {
  margin: 0 0 2px;
}
.tp-widget-logo {
  margin: 0 auto;
  width: 65px;
}
@media screen and (min-width: 160px) {
  .tp-widget-wrapper {
    font-size: 0;
  }
  .tp-widget-trustscore,
  .tp-widget-rating {
    display: inline-block;
    vertical-align: bottom;
  }
  .tp-widget-trustscore {
    margin: 0 6px 8px 0;
  }
  .tp-widget-rating {
    font-size: 12px;
    margin: 0 0 8px;
  }
}
@media screen and (min-width: 230px) {
  .tp-widget-trustscore {
    font-size: 15px;
  }
  .tp-widget-rating {
    font-size: 13px;
    margin: 0 6px 8px 0;
  }
  .tp-widget-logo {
    display: inline-block;
    margin: 0 0 8px;
    vertical-align: bottom;
    width: 75px;
  }
}
@media screen and (min-width: 300px) {
  .tp-widget-trustscore {
    font-size: 16px;
    margin: 0 12px 8px 0;
  }
  .tp-widget-rating {
    font-size: 14px;
    margin: 0 8px 8px 0;
  }
}
