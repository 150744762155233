label.a6y-react-auth__checkbox-label input:checked ~ .a6y-react-auth__checkbox-label__input--checkmark {
  @apply bg-blue-500;
}
label.a6y-react-auth__checkbox-label input:checked ~ .a6y-react-auth__checkbox-label__input--checkmark:after {
  @apply border-white;
  left: 4px!important;
  top: 1px!important;
  width: 5px!important;
  height: 9px!important;
  border-width: 0 2px 2px 0!important;
}

.a6y-react-auth__form--error input {
  border-color: red !important;
}

.a6y-react-auth__social-login {
  @apply pb-4 mb-4 border-b border-gray-200;
}
