.burger-menu {
  position: absolute;
  width: 25px;
  margin-top: 20px;
  margin-left: 15px;
  z-index: 900;
  animation: right 10s linear;
  animation-delay: 2s;
  right: 90vw;
}

.burger-menu__wide {
  right: 10vw;
  width: 25px;
  animation: right 10s linear;
  animation-delay: 2s;
}
